import React, { useState, useEffect } from 'react';
import styles from './styles';
import { useLocation } from '@reach/router';
import { Link as RouterLink, navigate } from 'gatsby';
import {
	Grid,
	Button,
	CircularProgress,
	Link,
	InputAdornment,
	IconButton,
	Stack,
	Container,
	Box,
	SvgIcon,
} from '@mui/material';
import * as Yup from 'yup';
import { useTheme } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';

import { makePostRequest } from '@helpers/requests';
import { USER_LOGIN } from '@helpers/api';
import { loginUser } from '@store/user/actions';
import { UserState } from '@store/user/types';
import { Typography } from '@mui/material';
import { useSnackbar } from '@components/common/Snackbar';
import Layout from '@components/common/Layouts/Basic';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { HeaderHeightContext } from '@helpers/contexts';
import SEO from '@components/SEO';

const Login = () => {
	const theme = useTheme();
	const location = useLocation();
	const [openSnackbar] = useSnackbar();
	const dispatch = useDispatch();
	const { user } = useSelector(({ user }) => ({ user }));
	const [showPassword, setShowPassword] = useState<boolean>(false);

	useEffect(() => {
		if (user) {
			navigate('/dashboard/cards');
			return;
		}
	}, [user]);

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const handleSubmit = async (values) => {
		try {
			const { data } = await makePostRequest(USER_LOGIN, values);
			await dispatch(loginUser(data));
		} catch (error) {
			openSnackbar(error?.errorMessage ?? 'An error occurred attempting to log in.', 'error');
		}
	};

	const initialValues: { email: string; password: string } = { email: '', password: '' };

	return (
		<Layout
			HeaderProps={{ contained: false, simpleLogo: true }}
			disableGutterTop
			disableGutterBottom
		>
			<SEO title="Login" />
			<HeaderHeightContext.Consumer>
				{({ height }) => (
					<Stack direction="row" sx={{ height: '100%' }}>
						<Box
							sx={{
								width: 450,
								display: { xs: 'none', lg: 'block' },
								marginTop: `-${height}px`,
							}}
						>
							<Box
								sx={{
									height: '100%',
									background: theme.palette.primary.main,
									py: 3,
									pl: 4,
									pr: 3,
									flexGrow: 1,
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<Typography variant="h2" sx={{ color: 'common.white' }}>
									Hi, welcome back
								</Typography>
							</Box>
						</Box>

						<Stack
							sx={{ height: '100%', flexGrow: 1 }}
							justifyContent="center"
							alignItems="center"
						>
							<Formik
								initialValues={initialValues}
								validationSchema={Yup.object().shape({
									email: Yup.string()
										.email('Must be an email')
										.required('Email is required'),
									password: Yup.string().required('Password is required'),
								})}
								onSubmit={handleSubmit}
							>
								{({ isSubmitting }) => (
									<Container css={styles(theme)} maxWidth="sm">
										<Stack spacing={1}>
											<Form className="form">
												<Typography
													gutterBottom
													variant="h2"
													component="h1"
												>
													Log in to Wave Card
												</Typography>
												<Field
													component={TextField}
													name="email"
													type="email"
													label="Email"
													fullWidth
													autoComplete="email"
													margin="normal"
												/>

												<Field
													component={TextField}
													name="password"
													type={showPassword ? 'text' : 'password'}
													label="Password"
													fullWidth
													margin="normal"
													InputProps={{
														endAdornment: (
															<InputAdornment position="end">
																<IconButton
																	aria-label="toggle password visibility"
																	onClick={
																		handleClickShowPassword
																	}
																	onMouseDown={
																		handleMouseDownPassword
																	}
																>
																	{showPassword ? (
																		<Visibility />
																	) : (
																		<VisibilityOff />
																	)}
																</IconButton>
															</InputAdornment>
														),
													}}
												/>

												<Button
													type="submit"
													className="submit"
													fullWidth
													variant="contained"
													color="primary"
													disabled={isSubmitting}
													startIcon={
														isSubmitting ? (
															<CircularProgress
																color="inherit"
																size={20}
															/>
														) : undefined
													}
												>
													Log in
												</Button>
											</Form>

											<Stack direction="row" justifyContent="flex-end">
												<Typography>
													<Link
														sx={{ fontWeight: 'bold' }}
														component={RouterLink}
														to="/forgot-password"
														state={{
															from: (location?.state as any)?.from,
														}}
													>
														Forgot password?
													</Link>
												</Typography>
											</Stack>
										</Stack>
									</Container>
								)}
							</Formik>
						</Stack>
					</Stack>
				)}
			</HeaderHeightContext.Consumer>
		</Layout>
	);
};

export default Login;
